import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal';
import { BeatLoader } from 'react-spinners';
import { css } from "@emotion/react";
import axiosInstance from '../../helpers/axios';
import TextEditorEditMode from '../TextEditorEditMode/TextEditorEditMode';




const EditArticleModal = ({onOpen, onClose, article, refreshArticles}) => {

    const [responseMessage, setResponseMessage] = useState("");
    const [buttonLoadingProgress, setButtonLoadingProgress] = useState(false);
    const [caption, setCaption] = useState();
    const [editValue, setEditValue] = useState({});


    useEffect(() => {
        setEditValue(article)
    }, [article])

    const handleEditArticleValues = e =>{
        setEditValue({...editValue, [e.target.name]: e.target.value })
    }

    const getContent = (htmlString) => {
        setCaption(htmlString);
    }


    const handleEditArticle = async (e)=> {
        e.preventDefault();
        setButtonLoadingProgress(true);
        
        try {
            const res = await axiosInstance.put(`/Support/${article.id}`, {
                id: article.id,
                caption: editValue.caption,
                body: caption,
                publishDate: editValue.publishDate
            });

            if (res.data.success === true) {
                setButtonLoadingProgress(false);
                setResponseMessage(<h5 variant="body1" className="modalSuccessMessage">Article updated Successfully </h5>); 
                refreshArticles();
                setTimeout(() => {
                    setResponseMessage("");
                }, 3000);
            }
        } catch (error) {
            setButtonLoadingProgress(false);
            setResponseMessage(<h5 variant="body1" className="modalErrorMessage">Unable to edit article</h5>);
            setTimeout(() => {
                setResponseMessage("");
            }, 3000);
        }
    }

    return (
        <Modal
            open={onOpen}
            onClose={onClose}
            center
        >
            <div style={{paddingRight: "15px", paddingTop:"10px", fontFamily:'roboto'}}>
                <form action="" onSubmit={handleEditArticle}>
                    <h4 style={{fontSize: "20px", marginTop: "0px"}}>Edit</h4>
                    <div>
                        { responseMessage ? responseMessage :  ''}
                    </div>
                    <label htmlFor="">Title</label>
                    <input type="text" autoComplete="off" style={{marginTop: "10px", marginBottom: "10px"}}className="editModalFormInput"  name="caption" value={editValue?.caption}
                    onChange={handleEditArticleValues}/>


                    {/* <label htmlFor="">Published Date</label>
                    <input type="date" autoComplete="off" style={{marginTop: "10px", marginBottom: "10px"}} className="editModalFormInput"  name="caption" value={formatDate(editValue?.publishDate)}
                    onChange={handleEditArticleValues}/> */}
                    
                    {/* <label htmlFor="">Article Body</label>
                    <textarea name="" id="" cols="80" rows="4"
                    style={{marginTop: "10px", marginBottom: "10px"}} name="body" 
                    className="editModalTextArea" value={editValue?.body} 
                    onChange={handleEditArticleValues}> 
                    </textarea> */}

                    <div style={{width: "auto", marginTop: "0px", marginBottom: "20px"}}>
                        <TextEditorEditMode getContent={getContent} articleBody={editValue?.body} title="Body"/>
                    </div>
                    
                    <button className="addDiagnosisButton">
                    { !buttonLoadingProgress ? 'Save' :(<BeatLoader color={"#1C1045"} loading={buttonLoadingProgress} size={15} margin={2} className={css`margin-top: 5px;`}/>) } 
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default EditArticleModal
