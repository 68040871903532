import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./texteditor.css";

const TextEditor = ({getContent, title}) => {
    const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
);

// useEffect(() => {
//     console.log(editorState);
// }, [editorState]);

const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
};

const sendContent = () => {
    getContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
};

  return (
    <div>
        <p style={{marginBottom:"10px"}}>{title}</p>
        <div className="textEditorStyles">
            <Editor
                editorState={editorState}
                editorClassName="editorTextStyle"
                toolbarClassName="toolbarStyle"
                onEditorStateChange={handleEditorChange}
                // onEditorStateChange={setEditorState}
            />  
        </div>
    </div>
  );
}
export default TextEditor;