import { Popover } from "antd";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";

export default function TopNav({ pageTitle, user, showMobileNav, setShowMobileNav }) {
    const history = useHistory()
    const [arrow, setArrow] = useState('Hide');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    function logOut() {
        localStorage.removeItem('user')
        history.push('/login')
    }

    const popoverContent = (
        <div className="flex flex-col">
            <div className="flex items-center px-4 py-3 border-b-[0.5px] border-b-[#D0D5DD] gap-3">
                <img src='/profile.png' alt="profile" className="rounded-full w-10 h-10" />
                <div className="flex flex-col">
                    <p className="text-sm font-semibold text-primary-400">{user.firstName} {user.lastName}</p>
                    <p className="text-sm text-primary-100">{user.email}</p>
                </div>
            </div>
            <div className="flex cursor-pointer items-center px-4 py-3 border-b-[0.5px] border-b-[#D0D5DD] gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.667 13.9256C12.667 14.3345 12.3477 14.6663 11.9527 14.6663C11.5577 14.6663 11.2384 14.3345 11.2384 13.9256C11.2384 11.8834 9.63628 10.2219 7.66699 10.2219C5.69771 10.2219 4.09556 11.8834 4.09556 13.9256C4.09556 14.3345 3.77628 14.6663 3.38128 14.6663C2.98628 14.6663 2.66699 14.3345 2.66699 13.9256C2.66699 11.0663 4.91056 8.74042 7.66699 8.74042C10.4234 8.74042 12.667 11.0663 12.667 13.9256ZM7.66699 2.81449C8.45485 2.81449 9.09556 3.47893 9.09556 4.29597C9.09556 5.11301 8.45485 5.77745 7.66699 5.77745C6.87913 5.77745 6.23842 5.11301 6.23842 4.29597C6.23842 3.47893 6.87913 2.81449 7.66699 2.81449ZM7.66699 7.25893C9.24271 7.25893 10.5241 5.93005 10.5241 4.29597C10.5241 2.6619 9.24271 1.33301 7.66699 1.33301C6.09128 1.33301 4.80985 2.6619 4.80985 4.29597C4.80985 5.93005 6.09128 7.25893 7.66699 7.25893Z" fill="#344054" />
                </svg>
                <p className="text-sm font-medium text-primary-400">View profile</p>
            </div>
            <div className="flex cursor-pointer items-center px-4 py-3 border-b-[0.5px] border-b-[#D0D5DD] gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_1517_10426)">
                        <path d="M6.05967 5.99967C6.21641 5.55412 6.52578 5.17841 6.93298 4.9391C7.34018 4.69978 7.81894 4.6123 8.28446 4.69215C8.74998 4.772 9.17222 5.01402 9.47639 5.37536C9.78057 5.7367 9.94705 6.19402 9.94634 6.66634C9.94634 7.99967 7.94634 8.66634 7.94634 8.66634M7.99967 11.333H8.00634M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1517_10426">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <p className="text-sm font-medium text-primary-400">Support</p>
            </div>
            <div className="flex items-center px-4 py-3 gap-3 cursor-pointer" onClick={logOut}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="text-sm font-medium text-primary-400">Log out</p>
            </div>
        </div>
    );

    return (
        <>
            {/* Desktop Nav */}
            <div className="lg:flex px-8 font-inter hidden items-center py-3 justify-between w-full h-[72px] bg-white z-10 fixed transition-all duration-[400ms] md:pl-[280px]">
                <p className="text-2xl text-primary-300 ml-8">{pageTitle}</p>

                <div className="flex items-center gap-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M11.4417 17.5003C11.2952 17.7529 11.0849 17.9625 10.8319 18.1083C10.5788 18.254 10.292 18.3307 10 18.3307C9.70802 18.3307 9.42116 18.254 9.16814 18.1083C8.91513 17.9625 8.70484 17.7529 8.55833 17.5003M15 6.66699C15 5.34091 14.4732 4.06914 13.5355 3.13146C12.5979 2.19378 11.3261 1.66699 10 1.66699C8.67392 1.66699 7.40215 2.19378 6.46447 3.13146C5.52678 4.06914 5 5.34091 5 6.66699C5 12.5003 2.5 14.167 2.5 14.167H17.5C17.5 14.167 15 12.5003 15 6.66699Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Popover placement="bottom" content={popoverContent} arrow={mergedArrow}>
                        <img src='/profile.png' alt="profile" className="rounded-full w-10 h-10 cursor-pointer" />
                    </Popover>
                </div>
            </div>

            <div className="lg:hidden px-8 font-inter flex items-center py-3 justify-end w-full h-[62px] bg-white z-10 fixed transition-all duration-[400ms] md:pl-[280px]">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowMobileNav(!showMobileNav)} className='md:hidden cursor-point' width="38" height="38" viewBox="0 0 40 40" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58049 10H33.4188C34.2888 10 35.0005 10.7117 35.0005 11.5817V11.7517C35.0005 12.6217 34.2888 13.3333 33.4188 13.3333H6.58049C5.71049 13.3333 5.00049 12.6217 5.00049 11.7517V11.5817C5.00049 10.7117 5.71049 10 6.58049 10ZM33.4188 18.3333H6.58049C5.71049 18.3333 5.00049 19.045 5.00049 19.915V20.085C5.00049 20.955 5.71049 21.6667 6.58049 21.6667H33.4188C34.2888 21.6667 35.0005 20.955 35.0005 20.085V19.915C35.0005 19.045 34.2888 18.3333 33.4188 18.3333ZM33.4188 26.6667H6.58049C5.71049 26.6667 5.00049 27.3783 5.00049 28.2483V28.4183C5.00049 29.2883 5.71049 30 6.58049 30H33.4188C34.2888 30 35.0005 29.2883 35.0005 28.4183V28.2483C35.0005 27.3783 34.2888 26.6667 33.4188 26.6667Z" fill="#121212" />
                </svg>
            </div>


        </>
    )
}