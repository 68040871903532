import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
let headers = {
    'Content-Type': 'Application/Json',
    'Access-Control-Allow-Origin': '*'
}   

const axiosInstance = axios.create({
    baseURL:baseURL,
    headers: headers
});

export default axiosInstance;