import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {EditorState, convertToRaw, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./texteditoreditmode.css";

const TextEditorEditMode = ({getContent, articleBody, title}) => {
    const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(articleBody)
        ))
);


const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
};

const sendContent = () => {
    getContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
};

  return (
    <div>
        <p style={{marginBottom:"10px"}}>{title}</p>
        <div className="textEditorStyles">
            <Editor
                editorState={editorState}
                editorClassName="editorTextStyle"
                toolbarClassName="toolbarStyle"
                onEditorStateChange={handleEditorChange}
            />  
        </div>
    </div>
  );
}
export default TextEditorEditMode;