import React, { useEffect } from 'react'
import 'react-responsive-modal/styles.css';
import { Link, } from 'react-router-dom'
import { useState } from 'react';
import './orders.css';
import { ArrowDown2, ArrowUp2, SearchNormal1 } from 'iconsax-react';
import { BeatLoader } from "react-spinners";
import axiosInstance from '../../../helpers/axios';
import Layout from '../../Layout';
import { format } from 'date-fns';
import InvoicesTable from '../../InvoicesTable';
import RepairTable from '../../RepairTable';
import { RadialChart } from "react-vis";
import Chart from 'react-apexcharts'


const Dashboard = ({ user }) => {

    const [userOrders, setUserOrders] = useState([]);
    const [mostRepairedData, setMostRepairedData] = useState();
    const [mobilePhonePercentage, setMobilePhonePercentage] = useState(0);
    const [laptopPercentage, setLaptopPercentage] = useState(0);
    const [othersPercentage, setOthersPercentage] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [showInvoices, setShowInvoices] = useState(true);
    const [filteredUserOrders, setFilteredUserOrders] = useState([]);
    const [userOrder, setUserOrder] = useState({});
    const [dashboardData, setDashboardData] = useState();
    const [orderDetails, setOrderDetails] = useState({});
    const [tableLoadingProgress, setTableLoadingProgress] = useState(true);
    const [salesLoadingProgress, setSalesLoadingProgress] = useState(false);    
    const [dashboardLoadingProgress, setDashboardLoadingProgress] = useState(true);

    const [pieChartData, setPieChartData] = useState([]);
    const [series, setSeries] = useState([]);


    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        }
    }


    //TABLE STATES
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    //MODALS OPEN STATES 
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [statusCounter, setStatusCounter] = useState(0);
    const [searched, setSearched] = useState("");
    const [showMobileNav, setShowMobileNav] = useState(false)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // FETCH ORDERS    
    const fetchUserOrders = async () => {
        try {
            var responses = await Promise.all([
                axiosInstance.get('/Order/Admin'),
                axiosInstance.get('/Invoice/Admin')
            ])
            if (responses[0].data.success === true) {
                setUserOrders(responses[0].data.data);
            }
            if (responses[1].data.success === true) {
                setInvoices(responses[1].data.data);
            }
            setTableLoadingProgress(false);
        } catch (error) {
            setTableLoadingProgress(false);
            console.log(error)
        }
    }

    async function fetchDashboardData() {
        try {
            var response = await axiosInstance.get('/Admin/GetDashboardData')
            if (response.data.success === true) {
                setDashboardData(response.data.data);
            }
            setDashboardLoadingProgress(false);
        } catch (error) {
            setDashboardLoadingProgress(false);
            console.log(error)
        }
    }

    async function fetchSalesData() {
        try {
            let responses = await Promise.all([
                axiosInstance.get('/Admin/GetMostRepaired'),
                axiosInstance.get('/Admin/GenerateSalesReport')
            ])
            if (responses[0].data.success === true) {
                const repairdata = responses[0].data.data
                setMostRepairedData(responses[0].data.data)
                let percentage = (repairdata.totalLaptops / repairdata.totalOrders) * 100
                setLaptopPercentage(Number(percentage).toFixed(1))
                percentage = (repairdata.totalOthers / repairdata.totalOrders) * 100
                setOthersPercentage(Number(percentage).toFixed(1))
                percentage = (repairdata.totalPhones / repairdata.totalOrders) * 100
                setMobilePhonePercentage(Number(percentage).toFixed(1))

                setPieChartData([
                    { angle: repairdata.totalPhones, color: "#FE7D3B", label: "Mobile Phones" },
                    { angle: repairdata.totalLaptops, color: "#789DFB", label: "test 3" },
                    { angle: repairdata.totalOthers, color: "#E5E5E5", label: "test 4" },
                ])
            }
            if (responses[1].data.success === true) {
                setSeries([{
                    name: "Sales",
                    data: responses[1].data.data
                }])
            }
            setSalesLoadingProgress(false);
        } catch (error) {
            setDashboardLoadingProgress(false);
            console.log(error)
        }
    }

    useEffect(() => {
        fetchUserOrders();
        setInterval(() => {
            fetchUserOrders()
        }, 60000);
    }, []);

    useEffect(() => {
        fetchSalesData();
    }, []);

    useEffect(() => {
        fetchDashboardData()
    }, []);


    const refreshOrderFetch = async () => {
        setStatusCounter((c) => c + 1);
    }

    const showChangeStatusModal = (row) => {
        setUserOrder(row);
        setOpenChangeStatusModal(true);
    }

    const showDeleteOrderModal = (row) => {
        setOpenDeleteModal(true);
        setUserOrder(row)
    }


    const requestSearch = (searchedVal) => {
        if (!searchedVal) {
            setUserOrders(userOrders);
        }

        let newUserOrders = [...userOrders];
        const filteredRows = newUserOrders.filter((row) => {
            return row.displayName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setFilteredUserOrders(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (
        <Layout pageTitle={'Dashboard'} user={user}>
            <>
                {dashboardLoadingProgress ? <div className="flex w-full items-center justify-center py-10">
                    <BeatLoader color='#1F1844' size={20} />
                </div> : <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 px-3 md:px-0 pt-[46px] md:pt-0">
                    <div className="bg-white p-4 flex rounded-lg">
                        <div className="flex flex-col gap-2 w-full">
                            <p className="text-primary-100 text-sm">Sales</p>
                            <p className="text-[#443E63] text-[40px] font-bold">N{dashboardData ? Number(dashboardData?.totalSales).toLocaleString() + '.00' : 0}</p>
                            <div className="flex gap-1 w-full items-center">
                                <img src="/up-trend.svg" alt='up-trend' className='h-[8px] w-[14px]' />
                                <p className="text-[#039855] text-base">50%</p>
                                <p className="text-[#98A2B3] text-base">+30 today</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <img src="/more.svg" alt='up-trend' className='h-[17px] cursor-pointer' />
                        </div>
                    </div>
                    <div className="bg-white p-4 flex rounded-lg">
                        <div className="flex flex-col gap-2 w-full">
                            <p className="text-primary-100 text-sm">Customers</p>
                            <p className="text-[#443E63] text-[40px] font-bold">{dashboardData ? dashboardData?.totalCustomers : 0}</p>
                            <div className="flex gap-1 w-full items-center">
                                <img src="/up-trend.svg" alt='up-trend' className='h-[8px] w-[14px]' />
                                <p className="text-[#039855] text-base">10%</p>
                                <p className="text-[#98A2B3] text-base">+30 today</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <img src="/more.svg" alt='up-trend' className='h-[17px] cursor-pointer' />
                        </div>
                    </div>
                    <div className="bg-white p-4 flex rounded-lg">
                        <div className="flex flex-col gap-2 w-full">
                            <p className="text-primary-100 text-sm">Repair orders</p>
                            <p className="text-[#443E63] text-[40px] font-bold">{dashboardData ? dashboardData?.totalOrders : 0}</p>
                            <div className="flex gap-1 w-full items-center">
                                <img src="/down-trend.svg" alt='up-trend' className='h-[8px] w-[14px]' />
                                <p className="text-[#D92D20] text-base">4%</p>
                                <p className="text-[#98A2B3] text-base">+30 today</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <img src="/more.svg" alt='up-trend' className='h-[17px] cursor-pointer' />
                        </div>
                    </div>
                </div>}

                {salesLoadingProgress ? <div className="flex w-full items-center justify-center py-10">
                    <BeatLoader color='#1F1844' size={20} />
                </div> : <div className="flex mt-6 w-full flex-col md:flex-row gap-6">
                    <div className="bg-white rounded-lg px-6 py-4 flex flex-col gap-5 w-full md:w-0 md:min-w-max">
                        <div className='flex gap-4 items-center border-b-[0.5px] border-b-[#D0D5DD] pb-[11px]  w-full'>
                            <p className='text-sm text-primary-100 font-medium'>Most repaired device</p>
                        </div>

                        <div className="flex gap-6 items-center">
                            <div className="flex flex-col gap-8">
                                <p className="text-[#443E63] font-bold text-xl">{mostRepairedData ? mostRepairedData?.totalOrders : 0} Repairs</p>

                                <div className="flex flex-col gap-3">
                                    <div className="flex gap-[6px] items-center">
                                        <div className="w-[16.12px] h-2 bg-[#FE7D3B] rounded-[10px]"></div>
                                        <p className="font-bold text-[9.75px]">{mobilePhonePercentage}% <span className="text-primary-100">Mobile phone</span></p>
                                    </div>
                                    <div className="flex gap-[6px] items-center">
                                        <div className="w-[16.12px] h-2 bg-[#789DFB] rounded-[10px]"></div>
                                        <p className="font-bold text-[9.75px]">{laptopPercentage}% <span className="text-primary-100">Laptops</span></p>
                                    </div>
                                    <div className="flex gap-[6px] items-center">
                                        <div className="w-[16.12px] h-2 bg-[#E5E5E5] rounded-[10px]"></div>
                                        <p className="font-bold text-[9.75px]">{othersPercentage}% <span className="text-primary-100">Others</span></p>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <RadialChart
                                    colorType="literal"
                                    innerRadius={80}
                                    radius={50}
                                    showLabels={false}
                                    data={pieChartData}
                                    color={(d) => d.color}
                                    width={170}
                                    height={160}
                                    animation={"gentle"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-lg px-6 py-4 w-full flex flex-col">
                        <div className='flex gap-4 items-center border-b-[0.5px] border-b-[#D0D5DD] pb-[11px]  w-full'>
                            <p className='text-sm text-primary-100 font-medium'>Sales analytics</p>
                        </div>
                        <Chart options={options} series={series} type="line" height={250} />
                    </div>
                </div>}

                <div className="bg-white rounded-lg mt-6 w-full">
                    <div className="flex items-center gap-3 w-full py-5 px-6 border-b border-b-[#EAECF0]">
                        {showInvoices ? <p className="text-lg text-primary-300 font-medium">Recent invoices</p>
                            :
                            <p className="text-lg text-primary-300 font-medium">Repair orders</p>}
                        <div className="flex flex-col gap-0">
                            <button onClick={() => setShowInvoices(true)} disabled={showInvoices}>
                                <ArrowUp2 size="20" color="#443E63" variant="Bold" />
                            </button>
                            <button className='mt-[-5px]' onClick={() => setShowInvoices(false)} disabled={!showInvoices}>
                                <ArrowDown2 size="20" color="#443E63" variant="Bold" />
                            </button>
                        </div>
                    </div>

                    {/* Search X Filter */}
                    <div className='flex w-full py-3 px-6'>
                        <div className="flex w-full gap-3">
                            <div className={`border gap-2 py-[10px] flex px-[14px] items-center rounded-lg border-[#D0D5DD]`}>
                                <SearchNormal1 size="15" color="#667085" />
                                <input placeholder='Search' className={`outline-none bg-transparent p-0 text-base text-primary-100`} type='text' />
                            </div>
                            <button className='rounded-lg hidden md:block border border-[#D0D5DD] py-[10px] px-4'>
                                <div className='flex items-center gap-2'>
                                    <img src="/filter.svg" alt="filter" className='w-[20px] h-[20px]' />
                                    <p className="text-sm font-semibold text-primary-400">Filters</p>
                                </div>
                            </button>
                        </div>
                        <div className="flex hidden md:block w-full justify-end">
                            <button className='rounded-lg border border-[#D0D5DD] py-[10px] px-4 float-right'>
                                <div className='flex items-center gap-2'>
                                    <img src="/calendar.svg" alt="filter" className='w-[20px] h-[20px]' />
                                    <p className="text-sm font-semibold text-primary-400">{format(new Date(), "MMM dd")}</p>
                                </div>
                            </button>
                        </div>
                    </div>

                    {showInvoices ? <InvoicesTable tableData={invoices} tableLoadingProgress={tableLoadingProgress} key={1} />
                        :
                        <RepairTable tableData={userOrders} tableLoadingProgress={tableLoadingProgress} key={2} />}
                </div>
            </>
        </Layout>
    )
}

export default Dashboard
