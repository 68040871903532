import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router';
import axiosInstance from '../../../helpers/axios';
import Leftbar from '../../Leftbar';
import RightbarNav from '../../RightBarNav';
import "./orderdetails.css";
import { BeatLoader, ClipLoader } from 'react-spinners';
import { formatDate } from '../../../helpers/date';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import EditDetailsModal from '../../EditDetailsModal';
import AddInvoiceModal from '../../AddInvoice/AddInvoiceModal';
import AddDiagnosisModal from '../../AddDiagnosis/AddDiagnosisModal';
import { Link } from 'react-router-dom';
import Layout from '../../Layout';
import { ArrowDown2, ArrowRight2, Edit2, Eye } from 'iconsax-react';
import { Editor } from '@tinymce/tinymce-react';
import { Modal, Popover, message } from 'antd';
import { useMemo } from 'react';
import parse from 'html-react-parser';



const OrderDetailsPage = ({ user, toggleLeftBar, setToggleLeftBar }) => {

    const location = useLocation();
    const [content, setContent] = useState('')
    const [text, setText] = useState('')
    const orderId = location.pathname.split("/")[3];
    const [showBillingInfo, setShowBillingInfo] = useState(false);
    const [orderDetails, setOrderDetails] = useState();
    const [diagnosisDetails, setDiagnosisDetails] = useState({})
    const [pageLoadingProgress, setPageLoadingProgress] = useState(true);
    const [openDiagnosisDetails, setOpenDiagnosisDetails] = useState(false);
    const [invoiceButtonIsLoading, setInvoiceButtonIsLoading] = useState(false);
    const [diagnosisButtonIsLoading, setDiagnosisButtonIsLoading] = useState(false);
    const [openDiagnosis, setOpenDiagnosis] = useState(false);
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [invoicesToSave, setInvoicesToSave] = useState([{ title: '', amount: 0, isDiagnosis: false, isDeleted: false }]);
    const [confirmPaymentIsLoading, setConfirmPaymentIsLoading] = useState(false);
    const [openEditDetailsModal, setOpenEditDetailsModal] = useState(false);
    const [openAddInvoiceModal, setOpenAddInvoiceModal] = useState(false);
    const [openAddDiagnosisModal, setOpenAddDiagnosisModal] = useState(false);

    async function updateOrderStatus(statusId) {
        let temp_obj = { ...orderDetails }
        temp_obj.order.orderStatusId = statusId
        switch (statusId) {
            case 1:
                temp_obj.order.status = 'Processing'
                break;
            case 2:
                temp_obj.order.status = 'Available for pickup'
                break;
            case 4:
                temp_obj.order.status = 'Couple back'
                break;
            case 5:
                temp_obj.order.status = 'Completed'
                break;
        }
        setOrderDetails(temp_obj)

        try {
            const response = await axiosInstance.put(`/Order/Status/${orderDetails.order.id}`, { statusId, adminId: user.id })
            if (response.data.success === true) {
                message.success('Order status updated successfully')
                fetchOrderDetails()
            }

        } catch (error) {
            message.error('Oops! Something went wrong. Please try again later')
        }
    }

    const [arrow, setArrow] = useState('Hide');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const popoverContent = (
        <div className="flex flex-col">
            <div onClick={() => updateOrderStatus(1)} className="flex cursor-pointer items-center px-4 py-[10px] border-b border-b-[#F2F4F7] gap-3">
                <p className="text-sm font-medium text-primary-400">Processing</p>
            </div>
            <div onClick={() => updateOrderStatus(2)} className="flex cursor-pointer items-center px-4 py-[10px] border-b border-b-[#F2F4F7] gap-3">
                <p className="text-sm font-medium text-primary-400">Available for pickup</p>
            </div>
            <div onClick={() => updateOrderStatus(4)} className="flex cursor-pointer items-center px-4 py-[10px] border-b border-b-[#F2F4F7] gap-3">
                <p className="text-sm font-medium text-primary-400">Couple back</p>
            </div>
            <div onClick={() => updateOrderStatus(5)} className="flex items-center px-4 py-[10px] gap-3 cursor-pointer" >
                <p className="text-sm font-medium text-primary-400">Completed</p>
            </div>
        </div>
    );

    const editorRef = useRef(null);

    const onEditorChange = function (a, editor) {
        setContent(a);
        setText(editor.getContent({ format: "text" }));
    };

    const handleTitleChange = function (index, title) {
        setInvoicesToSave(invoicesToSave.map((invoice, listIndex) => (
            Number(listIndex) === Number(index) ? { ...invoice, title } : invoice
        )))
    };

    const handleAmountChange = function (index, amount) {
        setInvoicesToSave(invoicesToSave.map((invoice, listIndex) => (
            Number(listIndex) === Number(index) ? { ...invoice, amount } : invoice
        )))
    };

    const handleAddInvoice = function () {
        setInvoicesToSave([...invoicesToSave, { title: '', amount: 0, isDeleted: false }])
    };

    async function saveDiagnosis() {
        setDiagnosisButtonIsLoading(true)

        try {
            if (!orderDetails.diagnosis) {
                const response = await axiosInstance.post('/Diagnosis', {
                    guid: orderDetails.order?.guid,
                    title: '',
                    orderId: orderDetails.order?.id,
                    summary: content,
                    adminId: user.id
                })
                if (response.data.success === true) {
                    message.success('Diagnosis created successfully')
                    closeDiagnosisModal()
                    fetchOrderDetails()
                }
            } else {
                const response = await axiosInstance.put(`/Diagnosis/${orderDetails.diagnosis?.id}`, {
                    title: '',
                    orderId: orderDetails.order?.id,
                    summary: content,
                })
                if (response.data.success === true) {
                    message.success('Diagnosis updated successfully')
                    closeDiagnosisModal()
                    fetchOrderDetails()
                }
            }

        } catch (error) {
            message.error('Oops! Something went wrong. Please try again later')
        }

        setDiagnosisButtonIsLoading(false)
    }

    async function saveInvoices() {
        setInvoiceButtonIsLoading(true)
        let invoices = [];
        invoicesToSave.forEach((invoice) => {
            if (invoice.isDiagnosis) {
                return;
            }

            invoice.orderId = orderDetails.order.id
            invoices.push(invoice)
        })

        try {
            const response = await axiosInstance.post('/Invoice/Create', { invoices: invoices })
            if (response.data.success === true) {
                message.success('Invoices updated successfully')
                fetchOrderDetails()
                closeCreateInvoiceModal()
            }

        } catch (error) {
            message.error('Oops! Something went wrong. Please try again later')
        }

        setInvoiceButtonIsLoading(false)
    }

    function getInvoiceChip(record) {
        if (record.hasPaid) {
            return (
                <div className="flex w-full justify-start items-center">
                    <div className='bg-[#EEF3FF] rounded-2xl text-[#114FEE] text-xs text-center px-2 py-1'>
                        Paid
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flex w-full justify-start items-center">
                    <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                        Pending
                    </div>
                </div>
            );
        }
    }

    const fetchOrderDetails = async () => {
        try {
            const res = await axiosInstance.get(`/Order/OrderDetails/${orderId}`);
            if (res.data.success === true) {
                setOrderDetails(res.data.data);
                setDiagnosisDetails(res.data.data.diagnosis);
                setContent(res.data.data.diagnosis?.summary);
                setInvoicesToSave(res.data.data.invoices.map((invoice) => (
                    { id: invoice.id, title: invoice.title, amount: invoice.amount, isDiagnosis: invoice.isDiagnosis, isDeleted: false }
                )));
                setPageLoadingProgress(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function confirmPayment() {
        if (!orderDetails) {
            return;
        }

        setConfirmPaymentIsLoading(true)
        try {
            var response = await axiosInstance.get(`/Payment/ConfirmPayment?orderId=${orderDetails?.order?.id}`)
            if (response.data.success === true) {
                message.success('Order confirmed successfully')
                fetchOrderDetails()
            }
        } catch (error) {
            message.error('Something went wrong. Please try again later')
        }
        setConfirmPaymentIsLoading(false)
    }

    function getStatusClassNames(order) {
        switch (order.orderStatusId) {
            case 1:
                return 'bg-[#EEF3FF] text-[#114FEE]'
            case 2:
                return 'bg-[#FFEDED] text-[#E43B3B]'
            case 4:
                return 'bg-[#FFEDED] text-[#E43B3B]'
            case 5:
                return 'bg-[#ECFDF3] text-[#027A48]'
        }
    }

    useEffect(() => {
        fetchOrderDetails();
    }, []);

    useEffect(() => {
        let sum = 0
        invoicesToSave.forEach((invoice) => {
            if (!invoice.isDeleted) {
                sum += Number(invoice.amount)
            }
        })

        setTotalAmount(sum)
    }, [invoicesToSave]);

    function deleteInvoice(index) {
        setInvoicesToSave(invoicesToSave.map((invoice, listIndex) => (
            Number(listIndex) === Number(index) ? { ...invoice, isDeleted: true } : invoice
        )))
    }

    const closeDiagnosisDetailsModal = () => {
        setOpenDiagnosisDetails(false);
    }

    const handleOpenInvoiceModal = () => {
        setOpenInvoiceModal(true);
    }

    const closeInvoiceModal = () => {
        setOpenInvoiceModal(false);
    }

    const handleOpenCreateInvoiceModal = () => {
        setOpenCreateInvoiceModal(true);
    }

    const closeCreateInvoiceModal = () => {
        setOpenCreateInvoiceModal(false);
    }

    const handlOpenDiagnosisDetailsModal = () => {
        setOpenDiagnosisDetails(true);
    }

    const closeDiagnosisModal = () => {
        setOpenDiagnosis(false);
    }

    const handlOpenDiagnosisModal = () => {
        setOpenDiagnosis(true);
    }

    const handleOpenDetailsModal = () => {
        setOpenEditDetailsModal(true);
    }

    const showAddInvoiceModal = () => {
        setOpenAddInvoiceModal(true);
    }

    const showAdddDiagnosisModal = (row) => {
        setOpenAddDiagnosisModal(true);
    }

    return (
        <Layout pageTitle={'Order Details'} user={user}>
            {pageLoadingProgress ? <div className="flex w-full h-full items-center justify-center">
                <BeatLoader color='#1F1844' size={30} />
            </div> : <>

                {/* Diagnosis modal */}
                <Modal open={openDiagnosisDetails}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    onCancel={closeDiagnosisDetailsModal}>
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <div className="border-[#FEF3F2] bg-[#FEE4E2] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_556_9684)">
                                        <path d="M8 5.33325V7.99992M8 10.6666H8.00667M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8 14.6666C4.3181 14.6666 1.33333 11.6818 1.33333 7.99992C1.33333 4.31802 4.3181 1.33325 8 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_556_9684">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className='text-lg text-primary-400 font-medium'>Diagnosis</p>
                        </div>

                        <div className="mt-6 text-primary-100 text-lg">
                            {orderDetails?.diagnosis ? parse(orderDetails.diagnosis.summary) : 'No diagnosis report has been added'}
                        </div>
                    </div>
                </Modal>

                <Modal open={openDiagnosis}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    onCancel={closeDiagnosisModal}>
                    <div className=''>
                        <Editor
                            apiKey='zqjrzntz1wrhy7k11akovffbmxyqytfqksa6phxjzl9l8dkv'
                            onInit={(evt, editor) => editorRef.current = editor}
                            onEditorChange={onEditorChange}
                            value={content}
                            init={{
                                selector: 'textarea',
                                height: 600,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                    'insertdatetime', 'media', 'image', 'link', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks fontFamily fontsize | ' +
                                    'bold italic underline strikethrough forecolor | alignleft alignright aligncenter alignjustify ' + ' | link image media table mergetags | addcomment showcomments | ' +
                                    ' | checklist bullist numlist outdent indent | ' +
                                    'help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        <div className="flex w-full justify-end gap-3 pt-4 border-t-[0.5px] mt-2 border-t-[#D0D5DD]">
                            <button className='rounded-3xl border font-semibold text-sm border-primary-300 text-primary-300 text-center py-2 px-[14px]' onClick={closeDiagnosisModal}>
                                Cancel
                            </button>
                            <button disabled={orderDetails?.order?.isPaid} className='rounded-3xl bg-primary-300 font-semibold text-sm text-white text-center py-2 px-[14px]' onClick={saveDiagnosis}>
                                {diagnosisButtonIsLoading ? <div className='w-full justify-center h-full items-center'>
                                    <ClipLoader color='#fff' size={20} />
                                </div> : 'Save'}
                            </button>
                        </div>
                    </div>
                </Modal>

                {/* Invoice modal */}
                <Modal open={openInvoiceModal}
                    onCancel={closeInvoiceModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <div className="border-[#EEF3FF] bg-[#1851e430] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.33325H6C5.632 9.33325 5.33334 9.03458 5.33334 8.66658C5.33334 8.29858 5.632 7.99992 6 7.99992H8C8.368 7.99992 8.66667 8.29858 8.66667 8.66658C8.66667 9.03458 8.368 9.33325 8 9.33325ZM5.33334 11.3333C5.33334 10.9653 5.632 10.6666 6 10.6666H10C10.3687 10.6666 10.6667 10.9653 10.6667 11.3333C10.6667 11.7013 10.3687 11.9999 10 11.9999H6C5.632 11.9999 5.33334 11.7013 5.33334 11.3333ZM11.6295 13.3333H4.3702C4.1662 13.3333 4.0002 13.1839 4.0002 12.9999V2.99992C4.0002 2.81592 4.1662 2.66659 4.3702 2.66659H8.0002V4.76659C8.0002 5.81459 8.81154 6.66658 9.80954 6.66658H12.0002V12.9999C12.0002 13.1839 11.8342 13.3333 11.6295 13.3333ZM9.33354 3.31859L11.1615 5.33325H9.80954C9.54687 5.33325 9.33354 5.07925 9.33354 4.76659V3.31859ZM13.1602 5.55192L9.53087 1.55192C9.4042 1.41259 9.22554 1.33325 9.03687 1.33325H4.3702C3.43087 1.33325 2.66687 2.08125 2.66687 2.99992V12.9999C2.66687 13.9186 3.43087 14.6666 4.3702 14.6666H11.6295C12.5689 14.6666 13.3335 13.9186 13.3335 12.9999V5.99992C13.3335 5.83392 13.2715 5.67459 13.1602 5.55192Z" fill="#1851E4" />
                                </svg>
                            </div>
                            <p className='text-lg text-primary-400 font-medium'>Invoices</p>
                        </div>
                        {orderDetails?.invoices.length > 0 ? <>

                            {/* Invoice info */}
                            <div className="rounded-lg mt-6">
                                {/* Column header */}

                                <div className="grid grid-cols-2 w-full bg-[#F4F8FD] border-b border-b-[#EAECF0] px-6 py-3 rounded-t-lg">
                                    <p className="text-primary-100 text-base">
                                        Description
                                    </p>
                                    <p className="text-primary-100 text-base text-right">
                                        Amount
                                    </p>
                                </div>

                                {/* Invoices */}
                                {orderDetails?.invoices.map((invoice, index) => (<div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-6 py-4 border-b border-b-[#EAECF0]">
                                    <p className="text-primary-400 text-sm font-medium">
                                        {invoice.title}
                                    </p>
                                    <p className={`text-primary-400 text-sm text-right font-medium`}>
                                        N{Number(invoice.amount).toLocaleString()}
                                    </p>
                                </div>))}


                                {/* Total cost */}
                                <div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-7 py-4 rounded-b-lg">
                                    <p className="text-primary-400 text-base font-semibold">
                                        Total
                                    </p>
                                    <p className="text-primary-400 text-base text-right font-semibold">
                                        N{totalAmount.toLocaleString()}.00
                                    </p>
                                </div>

                            </div>
                        </> : <div className="mt-6 text-primary-100 text-lg">
                            No invoice has been added
                        </div>}
                    </div>
                </Modal>

                {/* Create invoice */}
                <Modal open={openCreateInvoiceModal}
                    onCancel={closeCreateInvoiceModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <div className="border-[#EEF3FF] bg-[#1851e430] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.33325H6C5.632 9.33325 5.33334 9.03458 5.33334 8.66658C5.33334 8.29858 5.632 7.99992 6 7.99992H8C8.368 7.99992 8.66667 8.29858 8.66667 8.66658C8.66667 9.03458 8.368 9.33325 8 9.33325ZM5.33334 11.3333C5.33334 10.9653 5.632 10.6666 6 10.6666H10C10.3687 10.6666 10.6667 10.9653 10.6667 11.3333C10.6667 11.7013 10.3687 11.9999 10 11.9999H6C5.632 11.9999 5.33334 11.7013 5.33334 11.3333ZM11.6295 13.3333H4.3702C4.1662 13.3333 4.0002 13.1839 4.0002 12.9999V2.99992C4.0002 2.81592 4.1662 2.66659 4.3702 2.66659H8.0002V4.76659C8.0002 5.81459 8.81154 6.66658 9.80954 6.66658H12.0002V12.9999C12.0002 13.1839 11.8342 13.3333 11.6295 13.3333ZM9.33354 3.31859L11.1615 5.33325H9.80954C9.54687 5.33325 9.33354 5.07925 9.33354 4.76659V3.31859ZM13.1602 5.55192L9.53087 1.55192C9.4042 1.41259 9.22554 1.33325 9.03687 1.33325H4.3702C3.43087 1.33325 2.66687 2.08125 2.66687 2.99992V12.9999C2.66687 13.9186 3.43087 14.6666 4.3702 14.6666H11.6295C12.5689 14.6666 13.3335 13.9186 13.3335 12.9999V5.99992C13.3335 5.83392 13.2715 5.67459 13.1602 5.55192Z" fill="#1851E4" />
                                </svg>
                            </div>
                            <p className='text-lg text-primary-400 font-medium'>Create Invoice</p>
                        </div>
                        {<>

                            {/* Invoice info */}
                            <div className="rounded-lg mt-6">
                                {/* Column header */}

                                <div className="grid grid-cols-3 w-full bg-[#F4F8FD] border-b border-b-[#EAECF0] px-6 py-3 rounded-t-lg">
                                    <p className="text-primary-100 text-base">
                                        Description
                                    </p>
                                    <p className="text-primary-100 text-base text-right">
                                        Amount
                                    </p>
                                    <p className="text-primary-100 text-base text-right">
                                        Action
                                    </p>
                                </div>

                                {/* Invoices */}
                                {invoicesToSave.map((invoice, index) => {
                                    if (!invoice.isDeleted) {
                                        return (<div className="grid grid-cols-3 w-full bg-[#F9FAFB] gap-6 px-6 py-4 border-b border-b-[#EAECF0]">
                                            <div className={`border py-[10px] flex px-[14px] rounded-lg border-[#D0D5DD]}`}>
                                                <input className={`w-full outline-none bg-transparent p-0 text-base text-primary-100}`} type='text' onChange={(e) => handleTitleChange(index, e.target.value)} value={invoicesToSave[index].title}
                                                    readOnly={invoice.isDiagnosis} />
                                            </div>
                                            <div className={`border py-[10px] flex px-[14px] rounded-lg border-[#D0D5DD]}`}>
                                                <input className={`w-full outline- text-right bg-transparent p-0 text-base text-primary-100}`} type='text' onChange={(e) => handleAmountChange(index, e.target.value)} value={invoicesToSave[index].amount} onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} readOnly={invoice.isDiagnosis} />
                                            </div>
                                            <div className="flex justify-end w-full items-center">
                                                <img src="/trash.png" alt="trash" className={`w-[20px] h-[20px] cursor-pointer ${invoice.isDiagnosis ? 'hidden' : 'block'}`} onClick={() => deleteInvoice(index)} />
                                            </div>
                                        </div>)
                                    }
                                })}

                                <div className="grid w-full bg-[#F9FAFB] gap-6 px-6 py-4 border-b border-b-[#EAECF0]">
                                    <button onClick={handleAddInvoice}>
                                        <div className="flex items-center gap-2">
                                            <p className="text-sm text-primary-400 font-medium">Add item</p>
                                            <img src="/plus.svg" alt="plus" className='w-[24px] h-[24px]' />
                                        </div>
                                    </button>
                                </div>

                                {/* Total cost */}
                                <div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-7 py-4 rounded-b-lg">
                                    <p className="text-primary-400 text-base font-semibold">
                                        Total
                                    </p>
                                    <p className="text-primary-400 text-base text-right font-semibold">
                                        N{totalAmount.toLocaleString()}.00
                                    </p>
                                </div>

                                <div className="flex w-full justify-end gap-3 pt-4 border-t-[0.5px] mt-2 border-t-[#D0D5DD]">
                                    <button className='rounded-3xl border font-semibold text-sm border-primary-300 text-primary-300 text-center py-2 px-[14px]' onClick={closeDiagnosisModal}>
                                        Cancel
                                    </button>
                                    <button disabled={orderDetails.order?.isPaid} onClick={saveInvoices} className='rounded-3xl bg-primary-300 font-semibold text-sm text-white text-center py-2 px-[14px]'>
                                        {invoiceButtonIsLoading ? <div className='w-full justify-center h-full items-center'>
                                            <ClipLoader color='#fff' size={20} />
                                        </div> : 'Save'}
                                    </button>
                                </div>

                            </div>
                        </>
                            // : <div className="mt-6 text-primary-100 text-lg">
                            //     No invoice has been added
                            // </div>
                        }
                    </div>
                </Modal>

                <div className="flex w-full flex-col md:flex-row gap-6">
                    {/* Customer information */}
                    <div className='flex flex-col bg-white border-[#EAECF0] rounded-lg border p-6 w-full' >
                        <div className='flex gap-4 items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4  w-full'>
                            <img src="/user.svg" alt="user" className='w-[24px] h-[24px]' />
                            <p className='text-lg text-primary-400 font-medium'>Customer Information</p>
                        </div>
                        <div className="grid grid-cols-2 gap-6 mt-6">
                            <div className="flex flex-col gap-1">
                                <p className="text-sm font-medium text-primary-100">Name</p>
                                <p className="text-sm text-primary-100">{orderDetails?.order?.customer?.firstName} {orderDetails?.order?.customer?.lastName}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-sm font-medium text-primary-100">Email</p>
                                <p className="text-sm text-primary-100">{orderDetails?.order?.customer?.email}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-sm font-medium text-primary-100">Address</p>
                                <p className="text-sm text-primary-100">{orderDetails?.order?.customer?.address}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-sm font-medium text-primary-100">Phone Number</p>
                                <p className="text-sm text-primary-100">{orderDetails?.order?.customer?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>

                    {/* Device info */}
                    <div className='flex flex-col bg-white w-full md:w-0 md:min-w-max border-[#EAECF0] rounded-lg border p-6' >
                        <div className='flex items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4  w-full gap-8'>
                            <p className='text-lg text-primary-400 font-medium'>Repair ID #{orderDetails.order?.displayName}</p>
                            <Popover placement="bottom" content={popoverContent} arrow={mergedArrow}>
                                <div className={`items-center cursor-pointer flex gap-1 font-medium rounded-2xl text-xs text-center px-2 py-1 ${getStatusClassNames(orderDetails?.order)}`}>
                                    {orderDetails.order.status === 'Cancelled' ? 'Couple back' : orderDetails.order.status} <ArrowDown2 size="20" />
                                </div>
                            </Popover>
                        </div>
                        <div className="grid grid-cols-2 mt-6 text-primary-100 text-sm">
                            <div className="flex flex-col gap-1 mb-[25px]">
                                <p className='font-semibold'>Device type</p>
                                <p>{orderDetails.order?.device?.type}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className='font-semibold'>Device Brand</p>
                                <p>{orderDetails.order?.device?.brand}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className='font-semibold'>Device Model</p>
                                <p>{orderDetails.order?.device?.model}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Device issue */}
                <div className='flex mt-6 bg-white flex-col border-[#EAECF0] rounded-lg border p-6 w-full' >
                    <div className='flex gap-4 items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4  w-full'>
                        <div className="border-[#FFFAEB] bg-[#FEF0C7] rounded-[39px] border-8">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M7.99986 5.33339V8.00006M7.99986 10.6667H8.00653M6.85986 1.90672L1.21319 11.3334C1.09677 11.535 1.03517 11.7636 1.03452 11.9964C1.03387 12.2292 1.09419 12.4581 1.20948 12.6604C1.32477 12.8627 1.49101 13.0312 1.69167 13.1493C1.89232 13.2674 2.12039 13.3308 2.35319 13.3334H13.6465C13.8793 13.3308 14.1074 13.2674 14.3081 13.1493C14.5087 13.0312 14.675 12.8627 14.7902 12.6604C14.9055 12.4581 14.9659 12.2292 14.9652 11.9964C14.9646 11.7636 14.903 11.535 14.7865 11.3334L9.13986 1.90672C9.02101 1.71079 8.85368 1.5488 8.65399 1.43638C8.45431 1.32395 8.22902 1.26489 7.99986 1.26489C7.7707 1.26489 7.54542 1.32395 7.34573 1.43638C7.14605 1.5488 6.97871 1.71079 6.85986 1.90672Z" stroke="#DC6803" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p className='text-lg text-primary-400 font-medium'>Device Issue</p>
                    </div>
                    <div className="mt-6 text-primary-100 text-lg">
                        {orderDetails?.order?.device?.complaint}
                    </div>
                </div>

                {/*  Billing */}
                <div className='flex mt-6 bg-white flex-col border-[#EAECF0] rounded-lg border p-6 w-full' >
                    <div className='flex items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4  w-full'>
                        <div className="flex gap-4">
                            <img src="/text.svg" alt="text" className='w-[24px] h-[24px]' />
                            <p className='text-lg text-primary-400 w-[100px] font-medium'>Billing</p>
                            {getInvoiceChip(orderDetails?.order)}
                        </div>

                        <div className="flex justify-end w-full">
                            <ArrowDown2 size="24" color="#344054" className='cursor-pointer' onClick={() => setShowBillingInfo(!showBillingInfo)} />
                        </div>
                    </div>
                    {showBillingInfo && <div className="mt-4 transition-all ease-in-out duration-300 flex flex-col gap-6">
                        {/* Diagnosis */}
                        <div className="border-[#EAECF0] rounded-lg border px-6 pt-[27px] pb-[106px]">
                            <div className="flex border-b-[0.5px] border-b-[#D0D5DD] pb-4 mb-6">
                                <div className='flex gap-4 items-center w-full'>
                                    <div className="border-[#FEF3F2] bg-[#FEE4E2] rounded-[39px] border-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clip-path="url(#clip0_556_9684)">
                                                <path d="M8 5.33325V7.99992M8 10.6666H8.00667M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8 14.6666C4.3181 14.6666 1.33333 11.6818 1.33333 7.99992C1.33333 4.31802 4.3181 1.33325 8 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_556_9684">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p className='text-lg text-primary-400 font-medium'>Add diagnosis</p>
                                </div>

                                <div className="flex w-full justify-end items-center gap-4">
                                    <Eye size="20" color="#231F20" onClick={handlOpenDiagnosisDetailsModal} className='cursor-pointer' />
                                    <Edit2 size="20" color="#231F20" onClick={handlOpenDiagnosisModal} className='cursor-pointer' />
                                </div>
                            </div>

                            {orderDetails?.diagnosis ? <p className="text-lg text-primary-100">Diagnostic information has been added.</p>
                                : <p className="text-lg text-primary-100">No diagnosis has been added.</p>}
                        </div>

                        {/* Invoice */}
                        <div className="border-[#EAECF0] rounded-lg border px-6 pt-[27px] pb-[106px]">
                            <div className="flex border-b-[0.5px] border-b-[#D0D5DD] pb-4 mb-6">
                                <div className='flex gap-4 items-center w-full'>
                                    <div className="border-[#EEF3FF] bg-[#1851e430] rounded-[39px] border-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.33325H6C5.632 9.33325 5.33334 9.03458 5.33334 8.66658C5.33334 8.29858 5.632 7.99992 6 7.99992H8C8.368 7.99992 8.66667 8.29858 8.66667 8.66658C8.66667 9.03458 8.368 9.33325 8 9.33325ZM5.33334 11.3333C5.33334 10.9653 5.632 10.6666 6 10.6666H10C10.3687 10.6666 10.6667 10.9653 10.6667 11.3333C10.6667 11.7013 10.3687 11.9999 10 11.9999H6C5.632 11.9999 5.33334 11.7013 5.33334 11.3333ZM11.6295 13.3333H4.3702C4.1662 13.3333 4.0002 13.1839 4.0002 12.9999V2.99992C4.0002 2.81592 4.1662 2.66659 4.3702 2.66659H8.0002V4.76659C8.0002 5.81459 8.81154 6.66658 9.80954 6.66658H12.0002V12.9999C12.0002 13.1839 11.8342 13.3333 11.6295 13.3333ZM9.33354 3.31859L11.1615 5.33325H9.80954C9.54687 5.33325 9.33354 5.07925 9.33354 4.76659V3.31859ZM13.1602 5.55192L9.53087 1.55192C9.4042 1.41259 9.22554 1.33325 9.03687 1.33325H4.3702C3.43087 1.33325 2.66687 2.08125 2.66687 2.99992V12.9999C2.66687 13.9186 3.43087 14.6666 4.3702 14.6666H11.6295C12.5689 14.6666 13.3335 13.9186 13.3335 12.9999V5.99992C13.3335 5.83392 13.2715 5.67459 13.1602 5.55192Z" fill="#1851E4" />
                                        </svg>
                                    </div>
                                    <p className='text-lg text-primary-400 font-medium'>Create invoice</p>
                                </div>

                                <div className="flex w-full justify-end items-center gap-4">
                                    <Eye size="20" color="#231F20" onClick={handleOpenInvoiceModal} className='cursor-pointer' />
                                    <Edit2 size="20" color="#231F20" onClick={handleOpenCreateInvoiceModal} className='cursor-pointer' />
                                </div>
                            </div>

                            {orderDetails?.invoices.length > 0 ? <p className="text-lg text-primary-100">Invoice has been created.</p>
                                : <p className="text-lg text-primary-100">No invoice has been created.</p>}
                        </div>

                        <div className="flex w-full justify-end gap-3">
                            <button className='rounded-3xl border font-semibold text-sm border-primary-300 text-primary-300 text-center py-2 px-[14px]'>
                                Save as Draft
                            </button>
                            <button onClick={confirmPayment} disabled={orderDetails?.order?.isPaid} className='rounded-3xl bg-[#32D583] font-semibold text-sm text-white text-center py-2 px-[14px]'>
                                {invoiceButtonIsLoading ? <div className='w-full justify-center h-full items-center'>
                                    <ClipLoader color='#fff' size={20} />
                                </div> : 'Confirm Payment'}
                            </button>
                        </div>
                    </div>}
                </div>
            </>}
        </Layout>
    )
}

export default OrderDetailsPage
