import { Paper } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React, { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import axiosInstance from '../../../helpers/axios';
import Leftbar from '../../Leftbar';
import RightbarNav from '../../RightBarNav';
import "./feedbacks.css";

const Feedbacks = ({user}) => {
    
    const [currentNotificationId, setCurrentNotificationId] = useState(false);
    const [readNotificationsCount, setReadNotificationsCount] = useState(0);
    const [notificationsLoadingProgress, setNotificationsLoadingProgress] = useState(true);

    const [feedbacks, setFeedbacks] = useState([]);

    useEffect(() => {
        const fetchNotifications = async()=>{
            try {
                const res = await axiosInstance.get(`/Feedback`);
                if (res.data.success === true) {
                    setNotificationsLoadingProgress(false);
                    setFeedbacks(res.data.data);
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchNotifications();
        
    }, [readNotificationsCount])

    function formatDate(date) {
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        dayString = weekday[d.getDay()];
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return `${dayString}  ${[day, month, year].join('/')}`;
    }

    function formatTime(date) {
        let d = new Date(`${date}`);
        let hours = d.getHours();
        let minutes = d.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    function handleShowNotification(notification) {
        setCurrentNotificationId(notification.id);
    }

    return (
        <div className="notificationsPage">
            <div className="notificationSidebar">
                <Leftbar/>
            </div> 
            <div className="notificationsFeed">
                <RightbarNav pageTitle = 'Notifications'/>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    {/* <div className="notificationsDiv"> */}
                        <div className="notificationSubFeed">
                                {
                                    notificationsLoadingProgress ? (
                                        <div className="notificationsLoadingProgressDiv">
                                        <BeatLoader color={"#1C1045"} loading={notificationsLoadingProgress} size={15} margin={2} /> 
                                        </div>
                                    ) :
                                    feedbacks.map((feedback) => {
                                        return (
                                            <div className="notificationsDiv" 
                                            >
                                                <div className="notificationTitle" 
                                                    key={feedback.id} 
                                                    onClick={() => handleShowNotification(feedback)}
                                                >
                                                    <h5 className="notificationTitleText">{feedback.feedbackNo}</h5>
                                                    <div style={{display: "flex", alignItems: "center", paddingBottom: "5px"}}>
                                                        <EventIcon fontSize="small"/> 
                                                        <h6 className="notificationTitleDate">{formatDate(feedback.date)}</h6>
                                                    </div>
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <ScheduleIcon fontSize="small"/>
                                                        <h6 className="notificationTitleDate">{formatTime(feedback.date)}</h6>
                                                    </div>
                                                </div>
                                                <div className="notificationBody">
                                                    <p style={feedback.id !== currentNotificationId ? {display: "none"}: undefined}>
                                                        {feedback.body}
                                                    </p>
                                                    <p style={feedback.id !== currentNotificationId ? {display: "none"}: undefined}>Order Id: {feedback.orderId}</p>
                                                    <p style={feedback.id !== currentNotificationId ? {display: "none"}: undefined}>User Id: {feedback.userId}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }                   
                        </div>
                    {/* </div> */}
                </Paper>
            </div>
        </div>
    )
}

export default Feedbacks
