import { BeatLoader } from "react-spinners";
import { useRef, useState } from "react";
import { Image, Modal, Progress, Table, message } from "antd";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

export default function RepairTable({ tableData, tableLoadingProgress }) {
    const history = useHistory()
    //Order parameters

    const [open, setOpen] = useState(false)

    function getStatusChip(record) {
        switch (record.orderStatusId) {
            case 1:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#EEF3FF] rounded-2xl text-[#114FEE] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#ECFDF3] rounded-2xl text-[#027A48] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
        }
    }

    function getStatusProgress(orderStatusId) {
        switch (Number(orderStatusId)) {
            case 1:
                return (
                    <Progress percent={20} strokeColor={'#F04301'} />
                );
            case 2:
                return (
                    <Progress percent={60} strokeColor={'#F04301'} />
                );
            case 4:
                return (
                    <Progress percent={100} strokeColor={'#F04301'} />
                );
            case 5:
                return (
                    <Progress percent={100} strokeColor={'#32D583'} />
                );

        }
    }

    const columns = [
        {
            title: 'Repair ID',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (date) => format(new Date(date), "MMM dd, yyyy")
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <div className='flex flex-col'>
                    {getStatusChip(record)}
                    {getStatusProgress(record.orderStatusId)}
                </div>
            ),
        },
        {
            title: 'Action',
            render: (_, record) => (
                <Link to={`/order/details/${record.id}`}>
                    <p className='text-primary-300 text-sm font-semibold'>View Details</p>
                </Link>
            ),
        },
    ]

    return (
        <>
            {tableLoadingProgress ? <div className="flex w-full items-center justify-center py-10">
                <BeatLoader color='#1F1844' size={20} />
            </div> :
                <Table dataSource={tableData} columns={columns} />}
        </>
    )
}