import React, { useEffect } from 'react'
import 'react-responsive-modal/styles.css';
import { useState } from 'react';
import './orders.css';
import {  SearchNormal1 } from 'iconsax-react';
import { BeatLoader } from "react-spinners";
import axiosInstance from '../../../helpers/axios';
import Layout from '../../Layout';
import RepairTable from '../../RepairTable';
import { format } from "date-fns";



const Orders = ({ user }) => {

    const [userOrders, setUserOrders] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [showInvoices, setShowInvoices] = useState(true);
    const [filteredUserOrders, setFilteredUserOrders] = useState([]);
    const [userOrder, setUserOrder] = useState({});
    const [repairData, setRepairData] = useState();
    const [orderDetails, setOrderDetails] = useState({});
    const [tableLoadingProgress, setTableLoadingProgress] = useState(true);
    const [dashboardLoadingProgress, setDashboardLoadingProgress] = useState(true);

    //TABLE STATES
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    //MODALS OPEN STATES 
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [statusCounter, setStatusCounter] = useState(0);
    const [searched, setSearched] = useState("");
    const [showMobileNav, setShowMobileNav] = useState(false)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // FETCH ORDERS    
    const fetchUserOrders = async () => {
        try {
            var response = await axiosInstance.get('/Order/Admin')
            if (response.data.success === true) {
                setUserOrders(response.data.data);
            }
        } catch (error) {
            console.log(error)
        }
        setTableLoadingProgress(false);
    }

    async function fetchDashboardData() {
        try {
            var response = await axiosInstance.get('/Admin/GetRepairsData')
            if (response.data.success === true) {
                setRepairData(response.data.data);
                console.log(response.data.data);
            }
            setDashboardLoadingProgress(false);
        } catch (error) {
            setDashboardLoadingProgress(false);
            console.log(error)
        }
    }

    useEffect(() => {
        fetchUserOrders();
    }, []);

    useEffect(() => {
        fetchDashboardData()
    }, []);


    const refreshOrderFetch = async () => {
        setStatusCounter((c) => c + 1);
    }

    const showChangeStatusModal = (row) => {
        setUserOrder(row);
        setOpenChangeStatusModal(true);
    }

    const showDeleteOrderModal = (row) => {
        setOpenDeleteModal(true);
        setUserOrder(row)
    }


    const requestSearch = (searchedVal) => {
        if (!searchedVal) {
            setUserOrders(userOrders);
        }

        let newUserOrders = [...userOrders];
        const filteredRows = newUserOrders.filter((row) => {
            return row.displayName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setFilteredUserOrders(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (
        <Layout pageTitle={'Orders'} showMobileNav={showMobileNav} user={user}>
            <>
                {dashboardLoadingProgress ? <div className="flex w-full items-center justify-center py-10">
                    <BeatLoader color='#1F1844' size={20} />
                </div> : <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 px-3 md:px-0 pt-[46px] md:pt-0">
                    <div className="bg-white p-4 flex rounded-lg">
                        <div className="flex flex-col gap-2 w-full">
                            <p className="text-primary-100 text-sm">Total Orders</p>
                            <p className="text-[#443E63] text-[40px] font-bold">{repairData ? repairData?.totalOrders : 0}</p>
                            <div className="flex gap-1 w-full items-center">
                                <img src="/up-trend.svg" alt='up-trend' className='h-[8px] w-[14px]' />
                                <p className="text-[#039855] text-base">50%</p>
                                <p className="text-[#98A2B3] text-base">+30 today</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <img src="/more.svg" alt='up-trend' className='h-[17px] cursor-pointer' />
                        </div>
                    </div>
                    <div className="bg-white p-4 flex rounded-lg">
                        <div className="flex flex-col gap-2 w-full">
                            <p className="text-primary-100 text-sm">Total Processing</p>
                            <p className="text-[#443E63] text-[40px] font-bold">{repairData ? repairData?.totalProcessing : 0}</p>
                            <div className="flex gap-1 w-full items-center">
                                <img src="/up-trend.svg" alt='up-trend' className='h-[8px] w-[14px]' />
                                <p className="text-[#039855] text-base">10%</p>
                                <p className="text-[#98A2B3] text-base">+30 today</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <img src="/more.svg" alt='up-trend' className='h-[17px] cursor-pointer' />
                        </div>
                    </div>
                    <div className="bg-white p-4 flex rounded-lg">
                        <div className="flex flex-col gap-2 w-full">
                            <p className="text-primary-100 text-sm">Couple Back</p>
                            <p className="text-[#443E63] text-[40px] font-bold">{repairData ? repairData?.totalCoupleBack : 0}</p>
                            <div className="flex gap-1 w-full items-center">
                                <img src="/down-trend.svg" alt='up-trend' className='h-[8px] w-[14px]' />
                                <p className="text-[#D92D20] text-base">4%</p>
                                <p className="text-[#98A2B3] text-base">+30 today</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <img src="/more.svg" alt='up-trend' className='h-[17px] cursor-pointer' />
                        </div>
                    </div>
                    <div className="bg-primary-300 p-4 flex rounded-lg">
                        <div className="flex flex-col gap-2 w-full">
                            <p className="text-white text-sm">Total Completed</p>
                            <p className="text-white text-[40px] font-bold">{repairData ? repairData?.totalCompleted : 0}</p>
                            <div className="flex gap-1 w-full items-center">
                                <img src="/up-trend.svg" alt='up-trend' className='h-[8px] w-[14px]' />
                                <p className="text-[#039855] text-base">50%</p>
                                <p className="text-[#98A2B3] text-base">+30 today</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <img src="/more.svg" alt='up-trend' className='h-[17px] cursor-pointer' />
                        </div>
                    </div>
                </div>}

                <div className="bg-white rounded-lg mt-6 w-full">
                    <div className="flex items-center gap-3 w-full py-5 px-6 border-b border-b-[#EAECF0]">
                        <p className="text-lg text-primary-300 font-medium">Repair orders</p>
                    </div>

                    {/* Search X Filter */}
                    <div className='flex w-full py-3 px-6'>
                        <div className="flex w-full gap-3">
                            <div className={`border gap-2 py-[10px] flex px-[14px] items-center rounded-lg border-[#D0D5DD]`}>
                                <SearchNormal1 size="15" color="#667085" />
                                <input placeholder='Search' className={`outline-none bg-transparent p-0 text-base text-primary-100`} type='text' />
                            </div>
                            <button className='rounded-lg border hidden md:block border-[#D0D5DD] py-[10px] px-4'>
                                <div className='flex items-center gap-2'>
                                    <img src="/filter.svg" alt="filter" className='w-[20px] h-[20px]' />
                                    <p className="text-sm font-semibold text-primary-400">Filters</p>
                                </div>
                            </button>
                        </div>
                        <div className="flex w-full justify-end hidden md:block">
                            <button className='rounded-lg border border-[#D0D5DD] py-[10px] px-4'>
                                <div className='flex items-center gap-2'>
                                    <img src="/calendar.svg" alt="filter" className='w-[20px] h-[20px]' />
                                    <p className="text-sm font-semibold text-primary-400">{format(new Date(), "MMM dd")}</p>
                                </div>
                            </button>
                        </div>
                    </div>

                    <RepairTable tableData={userOrders} tableLoadingProgress={tableLoadingProgress} key={2} />
                </div>
            </>
        </Layout>
    )
}

export default Orders
