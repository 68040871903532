import React from 'react';
import { Route, Redirect } from 'react-router-dom';  

function ProtectedRoutes({isAuth: isAuth, user: user, component: Component, ...rest}) {
    return <Route {...rest} render={(props) => {
        if (isAuth) {
            return <Component user={user}/>
        } else{
            return (<Redirect to={{pathname: "/login", state: { from: props.location} }}/>);
        }
    }}
    />
}

export default ProtectedRoutes
