import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Leftbar from '../../Leftbar';
import RightbarNav from '../../RightBarNav';
import "./styles.css";
import Table from '@material-ui/core/Table';
import { BeatLoader } from "react-spinners";
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axiosInstance from '../../../helpers/axios';
import DatePicker from "react-date-picker";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';

ChartJs.register( Tooltip, Title, ArcElement, Legend );

const Statistics = () => {

    const  date = new Date();
    const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const [startDate, setStartDate] = useState(firstDayOfCurrentMonth)
    const [endDate, setEndDate] = useState(lastDayOfCurrentMonth)
    const [loadingProgress, setLoadingProgress] = useState(true)
    const [numberOfOrders, setNumberOfOrders] = useState(0)
    const [numberOfCompleteOrders, setNumberOfCompleteOrders] = useState(0)
    const [numberOfDeleteOrders, setNumberOfDeleteOrders] = useState(0)
    const [userData, setUserData] = useState([])
    const [statusCounter, setStatusCounter] = useState(0);
    
    //TABLE STATES
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    //SELECT
    const [sortByText, setSortByText] = React.useState('');
    const [open, setOpen] = React.useState(false);
    
    useEffect(() => {
        fetchReport(startDate, endDate);  
    }, [statusCounter]);
    
    const handleChange = (event) => {
        const value = event.target.value
        switch (value) {
            case 1: 
                userData.sort((a, b) => parseFloat(b.numberOrders) - parseFloat(a.numberOrders));
            break;
            case 2:
                userData.sort((a, b) => parseFloat(b.completeOrders) - parseFloat(a.completeOrders));
            break;
            case 3:
                userData.sort((a, b) => parseFloat(b.deletedOrders) - parseFloat(a.deletedOrders));
            break;
            default:
                break;
        }
        setSortByText(value)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // FETCH REPORT    
    const fetchReport = async(startDate, endDate)=>{
        try {
            console.log(`Start Date: ${startDate}`)
            console.log(`End Date: ${endDate}`)
            const res = await axiosInstance.post(`/Report/GenerateReport`, {
                startDate: formatDate(startDate), endDate: formatDate(endDate)
            },{headers: {'content-type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
            if (res.data.success === true) {
                console.log(res.data.data)
                setNumberOfCompleteOrders(res.data.data.numberOfCompleteOrders)
                setNumberOfDeleteOrders(res.data.data.numberOfDeleteOrders)
                setNumberOfOrders(res.data.data.numberOfOrders)
                setUserData(res.data.data.userOrderRankings)
                setLoadingProgress(false);
                setStartDate(startDate)
                setEndDate(endDate)
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const formatDate = (date) => {
        return date ? moment(date).format('YYYY-MM-DD') : date
    }

    const data = {
        labels: [
            'Nos of Orders',
            'Completed Orders',
            'Deleted Orders'
        ],
        datasets: [{
          label: 'Orders Track',
          data: [numberOfOrders, numberOfCompleteOrders, numberOfDeleteOrders],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
    };

    const noRecords = () => {
        return (
            <p>Hello world</p>
        );
    }

    const renderChart = () => {
        // return (
        //         <Pie data={data} style={{width:'50%', height:'50%', margin:'0 auto'}} 
        //         fallbackContent={() => noRecords()}
        //         />
        //     )
        if(numberOfOrders !== 0 || numberOfCompleteOrders !== 0  || numberOfDeleteOrders !== 0){
            return (
                <div style={{width:'300px', height:'300px'}}>
                    <Pie data={data} width="200" height="200" style={{width: '300px', height: '300px'}} 
                    fallbackContent={() => noRecords()}
                    />
                </div>
            )
        }else{
            return (<p className='norecord'>Oops!... No records Found</p>)
        }
    }

    
        return (
            <div className="statPage">
                <div className="statSidebar">
                    <Leftbar/>
                </div> 
                <div className="statFeed">
                    <RightbarNav pageTitle = 'Statistics'/>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <div className="statSubFeed">
                                        <div style={{width:'300px', height:'300px', margin: '0px auto'}}>
                                            {renderChart()}
                                        </div>

                                        <div className="dateBox">
                                            <div className="child"><DatePicker 
                                                className="startDate"
                                                selected={startDate}
                                                onChange={(date) => fetchReport(date, endDate)}
                                                selectsStart
                                                value={startDate}
                                                format="y-MM-dd"
                                                name='Select Start Date'
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            /></div>
                                            
                                            <div className="child"><DatePicker
                                                className="endDate"
                                                onChange={(date) => fetchReport(startDate, date)}
                                                selectsEnd
                                                value={endDate}
                                                format="y-MM-dd"
                                                placeholderText='Select End Date'
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            /></div>
                                            <div className="child">
                                            <label for="sort" margin-right="10px">Sort By</label>
                                            {/* <InputLabel id="demo-controlled-open-select-label">
                                                Sort By
                                            </InputLabel> */}
                                                <Select
                                                className="nosorder"
                                                padding-right="50px"
                                                margin-left="5px"
                                                padding-left="5px"
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                open={open}
                                                onClose={handleClose}
                                                onOpen={handleOpen}
                                                value={sortByText}
                                                onChange={handleChange}
                                                // className="MuiSelect-select"
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Nos of Orders</MenuItem>
                                                <MenuItem value={2}>Nos of Completed Orders</MenuItem>
                                                <MenuItem value={3}>Nos of Deleted Orders</MenuItem>
                                                </Select> 
                                            </div>
                                        </div>
                                    </div>

                                    <TableContainer sx={{ maxHeight: 40 }}>
                                        <Table stickyHeader aria-label="sticky table" className='sticky-table'>
                                            <TableHead className='TableHead'>
                                                <TableRow>
                                                <TableCell className="">Customer Name</TableCell>
                                                <TableCell className="">No. of Orders</TableCell>
                                                <TableCell className="">Completed Orders</TableCell>
                                                <TableCell className="">Deleted Orders</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            { loadingProgress ? (
                                                <TableRow  >                                            
                                                    <TableCell></TableCell>                                            
                                                    <TableCell></TableCell>
                                                    
                                                    <TableCell >
                                                        <div className="tableLoadingProgressDiv">
                                                            <BeatLoader color={"#1C1045"} loading={loadingProgress} size={10} /> 
                                                        </div>
                                                    </TableCell>
                                                    
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            ) :
                                                userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                    <TableRow key={index}>
                                                        <TableCell>{row.fullName}</TableCell>
                                                        <TableCell>{row.numberOrders}</TableCell>
                                                        <TableCell>{row.completeOrders}</TableCell>
                                                        <TableCell>{row.deletedOrders}</TableCell>
                                                    </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 25, 50, 100]}
                                        component="div"
                                        count={userData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                    </Paper>
                </div>
            </div>
        )
}

export default Statistics
