import React, { useEffect, useState } from 'react'
import { Card, Typography, CardContent, CardActions } from '@material-ui/core';
import RightbarNav from '../../RightBarNav';
import Leftbar from '../../Leftbar';
import "./support.css";
import { Delete, Edit } from '@material-ui/icons';
import { Modal } from 'react-responsive-modal';
import axiosInstance from '../../../helpers/axios';
import { BeatLoader } from 'react-spinners';
import EditArticleModal from '../../EditArticleModal/EditArticleModal';
import AddArticleModal from '../../AddArticleModal/AddArticleModal';

const Support = () => {

    const [open, setOpen] = useState(false);
    const [supportArticlesArr, setSupportArticlesArr] = useState([]);
    const [articleObj, setArticleObj] = useState({});
    const [loadingProgress, setLoadingProgress] = useState(true);
    const [openAddArticle, setOpenAddArticle] = useState(false);
    const [openEditArticle, setOpenEditArticle] = useState(false);
    
    const fetchArticles = async()=>{
        try {
            const res = await axiosInstance.get('/Support');
            if (res.data.success === true) {
                setSupportArticlesArr(res.data.data.sort((dateA, dateB) => new Date(dateB.publishDate) - new Date(dateA.publishDate)))
                setTimeout(() => {
                    setLoadingProgress(false);
                }, 3000);
            }
        } catch (error) {
            // console.log(error)
            return null;
        }
    }
    
    useEffect(() => {   
        fetchArticles();       
    }, []);
    
    
    const handleOpenModal = (article) => {
        setArticleObj(article);
        setOpen(true);
    }
    
    const closeUserModal = () => {
        setOpen(false);
    }    

    const handleOpenAddArticleModal = () => {
        setOpenAddArticle(true);
    }
    
    const closeAddArticle = () => {
        setOpenAddArticle(false);
    }   
    
    
    const handleOpenEditArticleModal = (article) => {
        setArticleObj(article);
        setOpenEditArticle(true);
    }
    
    const closeEditArticle = () => {
        setOpenEditArticle(false);
    }    

    const handleDeleteArticle = async(id) => {
        setSupportArticlesArr(supportArticlesArr.filter((article) => article.id !== id));
        try {
            const res = await axiosInstance.delete(`/Support/${id}`);
            if (res.data.success === true) {
                fetchArticles();
            }   
        } catch (error) {
            return null;
            // console.log(error);
        }

    }

    function formatDate(date) {
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        dayString = weekday[d.getDay()];
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return `${dayString}  ${[day, month, year].join('/')}`;
    }

    return (
        <div className="supportPage">
            <div className="menusidebar">
                <Leftbar/>
            </div> 
            <div className="supportFeed">
                <RightbarNav pageTitle = "Support Articles"/>
                <div>
                    <div className="addArticleButtonDiv">
                        <button className="addArticleButton" onClick={handleOpenAddArticleModal}>
                            Add Article
                        </button>
                    </div>
                    <div className="cardDivs" style={{display: "flex"}}> 
                        {
                            loadingProgress ? (
                                <div className="notificationsLoadingProgressDiv">
                                <BeatLoader color={"#1C1045"} loading={loadingProgress} size={15} margin={2} /> 
                                </div>
                            ) :
                            supportArticlesArr.map((article) => {
                                return (
                                    <div className="cards" key={article.id}>
                                        <Card className="cardStyle" >
                                            <CardContent>
                                                <Typography variant="h5"  gutterBottom style={{color: "#1C1045"}}>
                                                    {article.caption}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {formatDate(article.publishDate)}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <button className="viewMoreButton" onClick={() => handleOpenModal(article)}>Read</button>
                                                <button className="editArticleButton" onClick={() => handleOpenEditArticleModal(article)}><Edit style={{height: 20}}/> </button>
                                                <button className="editArticleButton" onClick={() => handleDeleteArticle(article.id)}><Delete style={{height: 20}} /> </button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <Modal 
                    open={open}
                    onClose={closeUserModal}
                    center
                >
                        <h5 className="modalHeader">{articleObj.caption}</h5>
                        <p className="modalBody" dangerouslySetInnerHTML={{__html: articleObj.body}}></p>
                </Modal>

                <AddArticleModal onOpen={openAddArticle} onClose={closeAddArticle} refreshArticles={fetchArticles}/>
                <EditArticleModal onOpen={openEditArticle} onClose={closeEditArticle} article={articleObj} refreshArticles={fetchArticles}/>
            </div>
        </div>
    )
}

export default Support
