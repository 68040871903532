import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import './styles.css';
import { useHistory } from 'react-router';
import Leftbar from '../../Leftbar';
import RightbarNav from '../../RightBarNav';
import axiosInstance from '../../../helpers/axios';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        height: "100vh",
        color: "white",
        paddingTop: 20,
        flex: 2, 
        backgroundColor: "#1C1045",
        top: 0, 
        marginBottom: 0,
    },
    logoGrp:{
        marginBottom: "30px",
        marginTop: "-20px",
    },
    logoLg: {
        display: "block",
        maxWidth: "45%",
        height: "auto",
    },
    
    form: {
        padding: theme.spacing(2),
    },
    item: {
        marginBottom: theme.spacing(4),
    },
    label:{
        color: "#E56F09",
    },
    borderbottom: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E56F09"
        },
     },
    textfield:{
        color: "#1C1045",
    },
  
}));
const Profile = ({user}) => {

    const classes = useStyles();

    
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [activateEditeDetails, setActivateEditeDetails] = useState(false);
    const [fullName, setFullName] = useState(user.fullName);
    const [address, setAddress] = useState(user.address);
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
    const [email, setEmail] = useState(user.email);
    const [responseMessage, setResponseMessage] = useState("");

    const history = useHistory();


    const enableEditDetails = () => {
      setActivateEditeDetails(true);
    }

    const handleUpdateProfile = async(e) => {
        e.preventDefault();
        if (!loadingProgress) {
          setLoadingProgress(true);
        }
        const userUpdated = {
          id: user.id,
          fullName: fullName,
          phoneNumber: phoneNumber,
          address: address,
          email: email
        } 
        try {
          const res = await axiosInstance.post(`/User/UpdateDetails`, userUpdated, {headers: {'Content-Type': 'application/json'}});
          if (res.data.success === true) {
            localStorage.setItem('user', JSON.stringify(res.data.data));
            setLoadingProgress(false);
            setResponseMessage(<Typography variant="h6" className="successMessage">{res.data.info}</Typography>)
            // setActivateEditeDetails(false);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
      } catch (error) {
        setLoadingProgress(false);
        setResponseMessage(<Typography variant="h6" className="errorMessage">An Error has occured, please try again later</Typography>)
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } 
    }

    

    function handleGoBack(){
       history.goBack()
    }

    return (
        <div>
            <div container className="profileContainer">
                <div className="menusidebar">
                  <Leftbar/>
                </div>

                <div className="profileSideFeed">
                  <div className="updateProfileFeed">
                    <RightbarNav pageTitle = "View Your Profile Details"/>
                    <div className="requstContainer">
                      <div className={classes.logoGrp}>
                          {/* <Typography variant="h5" style={{ paddingTop: 20, paddingLeft: 10 }}>View Your Profile Details</Typography> */}
                          <div>                           
                            <div className="editDetailsContainer" style={{marginTop:"20px", width: "150px", paddingLeft: "10px" }}>
                              <button className="editDetailsActivate" onClick={enableEditDetails} disabled ={activateEditeDetails}>Update</button>
                            </div>
                          </div>
                      </div>

                      <div>
                            { responseMessage ? responseMessage :  ''}
                            {/* { responseMessage ? (<Typography variant="h5" className="successMessage">{responseMessage}</Typography>) :  ''} */}
                      </div>


                        <form action="" className={ classes.form } onSubmit={handleUpdateProfile}>
                              <div className={ classes.item }>
                                  <input type="text"  id="" autoComplete="off" className="formInput" disabled={!activateEditeDetails} value={fullName} 
                                  onChange={(e) => setFullName(e.target.value)}/>
                              </div>
                              <div className={ classes.item }>
                                  <input type="text" disabled={!activateEditeDetails} id="" autoComplete="off" className="formInput"  value={phoneNumber}
                                  onChange={(e) => setPhoneNumber(e.target.value)}/>
                              </div>
                              {/* <div className={ classes.item }>
                                  <input type="text" disabled={!activateEditeDetails} id="" autoComplete="off" className="formInput" value={email} 
                                  onChange={(e) => setEmail(e.target.value)}/>
                              </div> */}
                              <div className={ classes.item }>
                                <textarea name="" id="" cols="80" rows="5" disabled={!activateEditeDetails} className="addressTextArea" value={address} 
                                    onChange={(e) => setAddress(e.target.value)}> </textarea>
                              </div>
                              
                             <div className="buttonDiv">
                              <button className="editSubmitButton" disabled ={!activateEditeDetails}>
                                { !loadingProgress ? 'SAVE' :  (<BeatLoader color={"#1C1045"} loading={loadingProgress} size={20} />)} 
                                </button> 
                             </div>
                          </form>
                    </div>
                  </div>
                </div>                        
            </div>
        </div>
    )
}

export default Profile
