import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Orders from './components/pages/Orders/Orders';
import Profile from './components/pages/Profile/Profile';
import OtpVerify from './components/pages/OtpVerification/OtpVerification';
import Login from './components/pages/Login/Login';
import ProtectedRoutes from './components/ProtectedRoutes';
import Feedbacks from './components/pages/Feedbacks/Feedbacks';
import Statistics from './components/pages/Statistics/Statistics';
import Diagnoses from './components/pages/Diagnoses/Diagnoses';
import Support from './components/pages/Support/Support';
import OrderDetailsPage from './components/pages/OrderDetailsPage/OrderDetailsPage';
import Dashboard from './components/pages/Dashboard/Dashboard';

function App() {
  let loggedIn = false;
  let user =localStorage.getItem('user');

  const userObj = JSON.parse(user);
  if(user) {
    loggedIn = true
  }

  return (
    <div>      
      <Router> 
        <Switch>
          
          {/* <Route path="/signup" exact>
              <SignUp user={userObj}/>
          </Route> */}
            
          <Route  path="/login" exact>
            <Login />
          </Route>

          <Route  path="/otpverification" exact>
              <OtpVerify />
          </Route>
          <ProtectedRoutes path="/" component={Dashboard} isAuth={loggedIn} user={userObj} exact/>
          <ProtectedRoutes path="/orders" component={Orders} isAuth={loggedIn} user={userObj}/>
          <ProtectedRoutes path="/diagnoses" component={Diagnoses} isAuth={loggedIn} user={userObj}/>
          <ProtectedRoutes path="/profile" component={Profile} isAuth={loggedIn} user={userObj}/>
          <ProtectedRoutes path="/support" component={Support} isAuth={loggedIn} user={userObj}/>
          <ProtectedRoutes path="/order/details/:orderId" component={OrderDetailsPage} isAuth={loggedIn} user={userObj}/>
          <ProtectedRoutes path="/statistics" component={Statistics} isAuth={loggedIn} user={userObj}/>
          {/* <ProtectedRoutes path="/invoice/:orderId" component={InvoicePage} isAuth={loggedIn} user={userObj}/> */}
          {/* <ProtectedRoutes path="/request" component={Request} isAuth={loggedIn} user={userObj}/>
          <ProtectedRoutes path="/orderhistory" component={OrderHistory} isAuth={loggedIn} user={userObj}/>
          <ProtectedRoutes path="/find-us" component={FindUs} isAuth={loggedIn} user={userObj}/> */}
          <ProtectedRoutes path="/feedbacks" component={Feedbacks} isAuth={loggedIn} user={userObj}/>
        </Switch>          
      </Router>
    </div>
  );
}

export default App;
