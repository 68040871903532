import React, {  useState } from 'react'
import { Modal } from 'react-responsive-modal';
import { BeatLoader } from 'react-spinners';
import { css } from "@emotion/react";
import axiosInstance from '../../helpers/axios';
import TextEditor from '../TextEditor/TextEditor';

const AddArticleModal = ({onOpen, onClose, refreshArticles}) => {

    const [responseMessage, setResponseMessage] = useState("");
    const [titleArticle, setTitleArticle] = useState("");
    const [bodyArticle, setBodyArticle] = useState("");
    const [buttonLoadingProgress, setButtonLoadingProgress] = useState(false);

    const getContent = (htmlString) => {
        setBodyArticle(htmlString);
    }

    const handleAddNewArticle = async (e)=> {
        e.preventDefault();
        setButtonLoadingProgress(true);
        
        try {
            const res = await axiosInstance.post(`/Support`, {
                caption: titleArticle,
                body: bodyArticle
            });

            if (res.data.success === true) {
                setButtonLoadingProgress(false);
                setResponseMessage(<h5 variant="body1" className="successMessage">Article Added Successfully</h5>); 
                setTitleArticle("");
                setBodyArticle("");
                refreshArticles();
                setTimeout(() => {
                    setResponseMessage("");
                }, 3000);
                console.log(bodyArticle);
            }
        } catch (error) {
            setButtonLoadingProgress(false);
            setResponseMessage(<h5 variant="body1" className="errorMessage">Unable to add article</h5>)
        }
    }

    return (
        <Modal 
            open={onOpen}
            onClose={onClose}
            center
        >
            <div style={{paddingRight: "15px", paddingTop:"10px", fontFamily:'roboto'}}>
                <form action="" onSubmit={handleAddNewArticle}>
                    <h4 style={{fontSize: "20px", marginTop: "0px"}}>Add New Article</h4>
                    <div>
                        { responseMessage ? responseMessage :  ''}
                    </div>
                    <label htmlFor="">Title</label>
                    <input type="text" autoComplete="off" style={{marginTop: "10px", marginBottom: "10px"}}className="editModalFormInput"  value={titleArticle}
                    onChange={(e) => setTitleArticle(e.target.value)}/>
                    
                    {/* <label htmlFor="">Article Body</label> */}                                
                    {/* <textarea name="" id="" cols="80" rows="4"
                    style={{marginTop: "10px", marginBottom: "10px"}} 
                    className="editModalTextArea" value={bodyArticle} 
                    onChange={(e) => setBodyArticle(e.target.value)}> 
                    </textarea> */}
                    
                    <div style={{width: "auto", marginTop: "0px", marginBottom: "20px"}}>
                        <TextEditor getContent={getContent} title="Body"/>
                    </div>
                    <button className="addButton" disabled={!titleArticle || !bodyArticle}>
                    { !buttonLoadingProgress ? 'Save' :(<BeatLoader color={"#1C1045"} loading={buttonLoadingProgress} size={15} margin={2} className={css`margin-top: 5px;`}/>) } 
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default AddArticleModal
