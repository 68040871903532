import { Typography } from '@material-ui/core';
import { AccountCircle, QuestionAnswer, Storefront } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { Link } from 'react-router-dom';
import { theme } from '../theme';

const useStyles = makeStyles({
    container: {
      marginBottom: "10px",
      display: "flex",
      paddingRight:"10px",
      paddingTop: "20px",
      paddingLeft: "10px",
      justifyContent: "space-between",
    },

    pagetitle: {
      [theme.breakpoints.down("sm")]: {
          display: "none"
      }
    },

    topLeft: {
      display: "flex",
      alignItems: "center",
      // paddingTop: 20,
      [theme.breakpoints.up("sm")]: {
          marginBottom: theme.spacing(3),
          cursor: "pointer"
      }
    },
  
    topRight: {
      display: "flex",
      // paddingTop: 20,
    },

    avatar: {
      width: 25,
      height: 25,
      borderRadius: "50%",
      cursor: "pointer"
    },

    icons: {
      marginRight: 12,
      color: "#1C1045"
    },
    
    
  });

const RightbarNav = ({ pageTitle }) => {
    const classes = useStyles();

    return (
         <div className={ classes.container }>
            <div className={classes.topLeft}>
                <Typography className={ classes.pagetitle } variant="h5" style={{ paddingTop: 0 }}> { pageTitle } </Typography>
            </div>  
            
            <div className= {classes.topRight}>
              {/* <div className= {classes.icons}>
                <Search/>
              </div> */}
              
              <Link to="/orders">
                <div className= {classes.icons}>
                  <Storefront />
                </div>
              </Link>

              <Link to="/feedbacks">
                <div className= {classes.icons}>               
                  <QuestionAnswer/>
                </div>
              </Link>
              <Link to="/profile">
                <div className= {classes.icons}>
                  {/* <img src="https://picsum.photos/200/100" alt="" className={ classes.avatar }/> */}
                  <AccountCircle/>
                </div>
              </Link>
              
            </div>
         </div>   
        
    )
}

export default RightbarNav
