import { Table } from "antd";
import { format } from "date-fns";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";

export default function InvoicesTable({ tableData, tableLoadingProgress }) {
    function getStatusChip(record) {
        if (record.hasPaid) {
            return (
                <div className="flex w-full justify-start">
                    <div className='bg-[#EEF3FF] rounded-2xl text-[#114FEE] text-xs text-center px-2 py-1'>
                        Paid
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flex w-full justify-start">
                    <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                        Pending
                    </div>
                </div>
            );
        }
    }

    const columns = [
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (date) => format(new Date(date), "MMM dd, yyyy")
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `${Number(amount).toLocaleString()}.00`
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <div className='flex'>
                    {getStatusChip(record)}
                </div>
            ),
        },
        {
            title: 'Action',
            render: (_, record) => (
                <Link to={`/order/details/${record.orderId}`}>
                    <p className='text-primary-300 text-sm font-semibold'>View Details</p>
                </Link>
            ),
        },
    ]


    return (
        <>
            {tableLoadingProgress ? <div className="flex w-full items-center justify-center py-10">
                <BeatLoader color='#1F1844' size={20} />
            </div> :
                <Table dataSource={tableData} columns={columns} />}
        </>
    )
}