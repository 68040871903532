import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { BeatLoader } from "react-spinners";
import logoLarge from "../../../assets/img/tb-logo-xd.png";
import './styles.css';
import axiosInstance from '../../../helpers/axios';

const useStyles = makeStyles((theme)=>({

    logoGrp:{
        marginBottom: "30px",
        paddingTop: "30px",

    },
    
    logoLg: {
        display: "block",
        maxWidth: "85%",
        height: "auto",
    },
    
    item: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: "50px",
        margin: "auto", 
        width:"100%"
    },

    label:{
        color: "#D3D3D3",
    },

    button:{
        backgroundColor: "#E56F09",
        borderRadius: "20px",
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 18,
        fontWeight: "700",
        color: "#1C1045",
        border: "none",
        cursor: "pointer",
        textAlign: "center",
    },
}));

const OtpVerify = () => {

    const classes = useStyles();

    const [otpCode, setOtpCode] = useState("");
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [canRequestOTPResend, setCanRequestOTPResend] = useState(false);
    const email =localStorage.getItem('email');

    
    const handleVerifyOtp = async(e) => {
        e.preventDefault()
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        setLoadingProgress(true)
        try {
            const res = await axiosInstance.post(`/Auth/VerifyToken`, {
                email: email,
                token: otpCode
            }, {
                headers: headers
            });

            if (res.data.success === true) {
                setLoadingProgress(false);
                setResponseMessage(<Typography variant="body1" className="successMessage">{res.data.info}</Typography>)
                localStorage.setItem('user', JSON.stringify(res.data.data.user));
                setTimeout(() => {
                    window.location.href = "/orders";
                }, 2000);
            }
            
        } catch (error) {
            setLoadingProgress(false)
            setResponseMessage(<Typography className="errorMessage">{error.response.data.info}</Typography>)
            setOtpCode("");
        }
    }

    const handleResendOTP = async(e) => {
        e.preventDefault();
        setCanRequestOTPResend(false);  
        try {
            const res = await axiosInstance.post(`/Auth/SendToken`, {
                email: email,
            }, {headers: {'Content-Type': 'Application/json'}});

            if (res.status === 200) {
                setTimeout(() => {
                    setCanRequestOTPResend(true);
                }, 10000);
                setResponseMessage(<Typography variant="body1" className="successMessage">OTP Code Resent</Typography>)
            }
        } catch (error) {
            console.log(error)
            setLoadingProgress(false)
            setResponseMessage(<Typography className="errorMessage">{error.response.data.info}</Typography>)
            setOtpCode("");
        } 
    }

    const handleValidateOtp = (e) =>{
        const val = (e.target.validity.valid) ? e.target.value : otpCode;
        setOtpCode(val)
    }

    useEffect(() => {        
        setTimeout(() => {
            setCanRequestOTPResend(true)
        }, 10000);
    }, []);

        return (
                <div className="verifyOtp">
                    <div className="OTPformContainer">
                        <div className={classes.logoGrp}>
                            <img src={logoLarge} alt="Techbase" className={classes.logoLg} />

                            <Typography variant="h4" style={{ paddingTop: 50, paddingLeft: 20, }}>OTP Verification</Typography>
                            
                            <Typography variant="body2" style={{ paddingTop: 20, paddingLeft: 20, fontStyle: "italic" }}>Please continue with the OTP code sent to your email at <span style={{color: '#E56F09', fontWeight: 700}}> {email} </span> </Typography>
                            
                            <button className="resendOTPButton"  disabled={!canRequestOTPResend} style={{ fontStyle: "italic" }}><span style={{color: '#E56F09', fontWeight: 700}} onClick={handleResendOTP}>Resend OTP </span> </button>

                            { responseMessage ? responseMessage :  ''} 
                        </div>
                        <div className="oTPFormGroup">  
                            <form action="" onSubmit={handleVerifyOtp}>
                                <div className="oTPInputGroup" style={{marginBottom: "20px", margin: "auto"}}>
                                    <input type="text" pattern="[0-9]*" maxLength={6} className="otpinput" value={otpCode} 
                                    onChange={handleValidateOtp} 
                                    // onChange={(e) => setOtpCode(e.target.value)} 
                                    autoComplete="off"/>  
                                </div>
                                <div className="buttonDiv"> 
                                    <button className="button" disabled ={!otpCode }>
                                        { !loadingProgress ? 'SUBMIT' :  (<BeatLoader color={"#1C1045"} loading={loadingProgress} size={15} margin={2} />)}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="imageCont" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/img-1.jpg"})`,}}></div>
                </div>
    
        )}

export default OtpVerify
